export const environment = {
  production: false,
};

export const apiHost: string = 'moes55emdf.execute-api.us-east-1.amazonaws.com';
export const apiUrl: string =
  'https://moes55emdf.execute-api.us-east-1.amazonaws.com/qa/api';

export const cognitoConfig = {
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_BJBPuCypS',
  aws_user_pools_web_client_id: '5oc73iom1sm2db2vgd71jkmrud',
  aws_cognito_identity_pool_id:
    'us-east-1:64f35a74-9ac3-415b-8123-b427d9a65561',
  API: {
    // TODO: dynamically create these endpoints from given environment
    endpoints: [
      {
        name: 'qa-parratus-api',
        endpoint: apiUrl,
      },
    ],
  },
};

export const tinyMceApiKey = 'zcl5if5vwe2ng1rpywiotmtslzf0blhq4de0vmnzqefiycba';
